import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { CustomDateParserFormatter } from './shared/formatters/custom-date-parser-formatter';
import { AppJwtInterceptor } from './shared/interceptors/app-jwt-interceptor';
import { LoadingIndicatorInterceptor } from './shared/interceptors/loading-indicator-interceptor';
import { ErrorHandlerService } from './shared/services/error-handler.service';
import { LoadingIndicatorService } from './shared/services/loading-indicator-service';
import { SharedModule } from './shared/shared.module';

@NgModule({ declarations: [
        AppComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        LayoutModule,
        SharedModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: () => {
                    return localStorage.getItem("jwt");
                },
                allowedDomains: [],
                disallowedRoutes: []
            }
        })], providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorHandlerService,
            multi: true,
        },
        { provide: HTTP_INTERCEPTORS, useClass: AppJwtInterceptor, multi: true },
        LoadingIndicatorService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoadingIndicatorInterceptor,
            multi: true,
            deps: [LoadingIndicatorService],
        },
        {
            provide: NgbDateParserFormatter,
            useValue: new CustomDateParserFormatter("dd-MMM-yyyy"), // <== format!
        },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
